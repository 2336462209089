<template>
  <b-form @submit.prevent>
    <b-row v-if="doctorReviewData.id && doctorReviewData.doctor">
      <b-col cols="12">
        <router-link :to="{name: 'doctors-update', params: {id: doctorReviewData.doctor.id }}">
          {{ doctorReviewData.doctor ? doctorReviewData.doctor.full_name : '' }}
        </router-link>
        {{ doctorReviewData.doctor ? '(' + doctorReviewData.doctor.city.name + ') - ' + (doctorReviewData.doctor.main_speciality !== null ? doctorReviewData.doctor.main_speciality.name : 'Основная специальность не выбрана!') : '' }}
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="6">
        <b-form-group
          label="Город:"
          label-for="city_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="city_id"
            vid="city_id"
          >
            <v-select
              v-model="doctorReviewData.city_id"
              label="name"
              :reduce="option => option.id"
              :options="citiesList"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Доктор:"
          label-for="doctor_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="doctor_id"
            vid="doctor_id"
          >
            <v-select
              id="doctor_id"
              v-model="doctorReviewData.doctor_id"
              label="full_name"
              :reduce="option => option.id"
              :options="doctor_options"
              :disabled="!doctorReviewData.city_id"
              @search="onSearch"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Тип:"
          label-for="type"
        >
          <ValidationProvider
            #default="{ errors }"
            name="type"
            vid="type"
          >
            <b-form-select
              id="type"
              v-model="doctorReviewData.type"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...DoctorsReviewsConstants.REVIEW_TYPE_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Статус:"
          label-for="status"
        >
          <ValidationProvider
            #default="{ errors }"
            name="status"
            vid="status"
          >
            <b-form-select
              id="status"
              v-model="doctorReviewData.status"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...DoctorsReviewsConstants.REVIEW_STATUS_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Текст отзыва:"
          label-for="description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="description"
            vid="description"
          >
            <b-form-textarea
              id="description"
              v-model="doctorReviewData.description"
              placeholder="Введите текст отзыва"
              rows="5"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка 1 - (Общая оценка):"
          label-for="rating"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating"
            vid="rating"
          >
            <b-form-input
              id="rating"
              v-model="doctorReviewData.rating"
              autocomplete="off"
              placeholder="Общая оценка"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка 2 - (Рейтинг доверия):"
          label-for="rating_confidence"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating_confidence"
            vid="rating_confidence"
          >
            <b-form-input
              id="rating_confidence"
              v-model="doctorReviewData.rating_confidence"
              autocomplete="off"
              placeholder="Рейтинг доверия"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка 3 - (Рейтинг уделения должного времени пациенту):"
          label-for="rating_competence"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating_competence"
            vid="rating_competence"
          >
            <b-form-input
              id="rating_competence"
              v-model="doctorReviewData.rating_competence"
              autocomplete="off"
              placeholder="Рейтинг уделения должного времени пациенту"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка 4 - (Рейтинг пунктуальности):"
          label-for="rating_punctuality"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating_punctuality"
            vid="rating_punctuality"
          >
            <b-form-input
              id="rating_punctuality"
              v-model="doctorReviewData.rating_punctuality"
              autocomplete="off"
              placeholder="Рейтинг пунктуальности"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка 5 - (Рейтинг ответов на вопросы):"
          label-for="rating_question"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating_question"
            vid="rating_question"
          >
            <b-form-input
              id="rating_question"
              v-model="doctorReviewData.rating_question"
              autocomplete="off"
              placeholder="Рейтинг ответов на вопросы"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row v-if="doctorReviewData.id">
      <b-col cols="6">
        <b-form-group
          label="IP адрес:"
          label-for="ip_address"
        >
          <ValidationProvider
            #default="{ errors }"
            name="ip_address"
            vid="ip_address"
          >
            <b-form-input
              id="ip_address"
              v-model="doctorReviewData.ip_address"
              autocomplete="off"
              disabled=""
              placeholder="Ip адрес"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Юзер Агнет:"
          label-for="user_agent"
        >
          <ValidationProvider
            #default="{ errors }"
            name="user_agent"
            vid="user_agent"
          >
            <b-form-input
              id="user_agent"
              v-model="doctorReviewData.user_agent"
              autocomplete="off"
              disabled=""
              placeholder="Юзер Агнет"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ doctorReviewData.id ? 'Одобрить, обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
      <div v-if="doctorReviewData.id">
        <b-button
          variant="danger"
          class="mr-1"
          @click="objectDelete(doctorReviewData.id)"
        >
          Удалить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
  BFormSelect,
  BFormTextarea,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import DoctorsReviewsConstants from '@/consts/doctorReviews'
import _ from 'lodash'
import axiosIns from '@/libs/axios'

export default {
  name: 'DoctorsReviewsForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  props: {
    doctorReviewData: {
      type: Object,
      require: true,
      default: () => {},
    },
    citiesList: {
      type: Array,
      require: true,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      DoctorsReviewsConstants,
    }
  },
  computed: {
    doctor_options() {
      return this.doctorOptions.map(({ full_name, id }) => ({ full_name, id }))
    },
  },
  watch: {},
  methods: {
    // eslint-disable-next-line consistent-return
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        return this.search(loading, search, this.doctorReviewData, this)
      }
    },
    search: _.debounce(async (loading, search, doctorReviewData, vm) => {
      const res = await axiosIns({
        method: 'GET',
        url: `/doctors/search?search=${search}&city_id=${doctorReviewData.city_id}`,
      })

      let list = res.data.data.map(({ full_name, id }) => ({ full_name, id }))
      list = _.merge(list, _.pickBy(vm.selectedOptions, _.identity))
      const uniqueRes = _.uniqBy(list, e => e.id)
      vm.$emit('update:doctorOptions', uniqueRes)
      loading(false)
    }, 350),
    async objectDelete(id) {
      const modalResult = await this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить этот отзыв?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (modalResult.value) {
        await axiosIns({
          method: 'DELETE',
          url: `/doctor_reviews/${id}/delete`,
        })
        await this.$router.push({ name: 'doctors-reviews' })

        this.$swal({
          icon: 'success',
          title: 'Удалено!',
          text: '',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
