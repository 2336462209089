var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.doctorReviewData.id && _vm.doctorReviewData.doctor)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{name: 'doctors-update', params: {id: _vm.doctorReviewData.doctor.id }}}},[_vm._v(" "+_vm._s(_vm.doctorReviewData.doctor ? _vm.doctorReviewData.doctor.full_name : '')+" ")]),_vm._v(" "+_vm._s(_vm.doctorReviewData.doctor ? '(' + _vm.doctorReviewData.doctor.city.name + ') - ' + (_vm.doctorReviewData.doctor.main_speciality !== null ? _vm.doctorReviewData.doctor.main_speciality.name : 'Основная специальность не выбрана!') : '')+" ")],1)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Город:","label-for":"city_id"}},[_c('ValidationProvider',{attrs:{"name":"city_id","vid":"city_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.citiesList},model:{value:(_vm.doctorReviewData.city_id),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "city_id", $$v)},expression:"doctorReviewData.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Доктор:","label-for":"doctor_id"}},[_c('ValidationProvider',{attrs:{"name":"doctor_id","vid":"doctor_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"doctor_id","label":"full_name","reduce":function (option) { return option.id; },"options":_vm.doctor_options,"disabled":!_vm.doctorReviewData.city_id},on:{"search":_vm.onSearch},model:{value:(_vm.doctorReviewData.doctor_id),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "doctor_id", $$v)},expression:"doctorReviewData.doctor_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Тип:","label-for":"type"}},[_c('ValidationProvider',{attrs:{"name":"type","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"type","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.DoctorsReviewsConstants.REVIEW_TYPE_LIST)},model:{value:(_vm.doctorReviewData.type),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "type", $$v)},expression:"doctorReviewData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Статус:","label-for":"status"}},[_c('ValidationProvider',{attrs:{"name":"status","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.DoctorsReviewsConstants.REVIEW_STATUS_LIST)},model:{value:(_vm.doctorReviewData.status),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "status", $$v)},expression:"doctorReviewData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Текст отзыва:","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите текст отзыва","rows":"5"},model:{value:(_vm.doctorReviewData.description),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "description", $$v)},expression:"doctorReviewData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка 1 - (Общая оценка):","label-for":"rating"}},[_c('ValidationProvider',{attrs:{"name":"rating","vid":"rating"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating","autocomplete":"off","placeholder":"Общая оценка"},model:{value:(_vm.doctorReviewData.rating),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "rating", $$v)},expression:"doctorReviewData.rating"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка 2 - (Рейтинг доверия):","label-for":"rating_confidence"}},[_c('ValidationProvider',{attrs:{"name":"rating_confidence","vid":"rating_confidence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating_confidence","autocomplete":"off","placeholder":"Рейтинг доверия"},model:{value:(_vm.doctorReviewData.rating_confidence),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "rating_confidence", $$v)},expression:"doctorReviewData.rating_confidence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка 3 - (Рейтинг уделения должного времени пациенту):","label-for":"rating_competence"}},[_c('ValidationProvider',{attrs:{"name":"rating_competence","vid":"rating_competence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating_competence","autocomplete":"off","placeholder":"Рейтинг уделения должного времени пациенту"},model:{value:(_vm.doctorReviewData.rating_competence),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "rating_competence", $$v)},expression:"doctorReviewData.rating_competence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка 4 - (Рейтинг пунктуальности):","label-for":"rating_punctuality"}},[_c('ValidationProvider',{attrs:{"name":"rating_punctuality","vid":"rating_punctuality"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating_punctuality","autocomplete":"off","placeholder":"Рейтинг пунктуальности"},model:{value:(_vm.doctorReviewData.rating_punctuality),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "rating_punctuality", $$v)},expression:"doctorReviewData.rating_punctuality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка 5 - (Рейтинг ответов на вопросы):","label-for":"rating_question"}},[_c('ValidationProvider',{attrs:{"name":"rating_question","vid":"rating_question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating_question","autocomplete":"off","placeholder":"Рейтинг ответов на вопросы"},model:{value:(_vm.doctorReviewData.rating_question),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "rating_question", $$v)},expression:"doctorReviewData.rating_question"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),(_vm.doctorReviewData.id)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"IP адрес:","label-for":"ip_address"}},[_c('ValidationProvider',{attrs:{"name":"ip_address","vid":"ip_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ip_address","autocomplete":"off","disabled":"","placeholder":"Ip адрес"},model:{value:(_vm.doctorReviewData.ip_address),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "ip_address", $$v)},expression:"doctorReviewData.ip_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,849280712)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Юзер Агнет:","label-for":"user_agent"}},[_c('ValidationProvider',{attrs:{"name":"user_agent","vid":"user_agent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_agent","autocomplete":"off","disabled":"","placeholder":"Юзер Агнет"},model:{value:(_vm.doctorReviewData.user_agent),callback:function ($$v) {_vm.$set(_vm.doctorReviewData, "user_agent", $$v)},expression:"doctorReviewData.user_agent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1373037156)})],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.doctorReviewData.id ? 'Одобрить, обновить и закрыть' : 'Создать и закрыть')+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1),(_vm.doctorReviewData.id)?_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.objectDelete(_vm.doctorReviewData.id)}}},[_vm._v(" Удалить ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }